import React from 'react'
import styled from 'styled-components'
import logo from '../../../src/assets/images/would-hub-logo.svg'

function ComingSoon() {
  return (
    <>
      <Container>
        <Cover className="wrapper">
          <Div>
            <Logocontainer>
              <Logo src={logo} alt="Logo" />
            </Logocontainer>
            <Button href="http://wouldhub.com">www.wouldhub.com</Button>
            <Header>Coming Soon</Header>
          </Div>
        </Cover>
      </Container>
    </>
  )
}

export default ComingSoon
const Container = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Cover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Header = styled.h1`
  color: #414042;
  font-size: 55px;
  @media all and (max-width: 980px) {
    font-size: 38px;
  }
  @media all and (max-width: 640px) {
    font-size: 30px;
  }
`
const Logocontainer = styled.div`
  width: 60%;
  margin-bottom: 40px;
  @media all and (max-width: 980px) {
    width: 50%;
  }
  @media all and (max-width: 640px) {
    width: 40%;
  }
`
const Logo = styled.img`
  display: block;
  width: 100%;
`
const Div = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
`
const Button = styled.a`
  padding: 10px;
  width: 70%;
  background: #8dc63f;
  color: #fff;
  border-radius: 30px;
  text-align: center;
  margin-bottom: 40px;
  font-size: 20px;
  cursor: pointer;
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
  @media all and (max-width: 640px) {
    font-size: 14px;
  }
`
